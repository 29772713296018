import React from 'react';
import styled from 'styled-components';
import { Link, navigate } from 'gatsby';
import { Decimal } from 'decimal.js-light';

// Cart
import { useShoppingCart } from 'use-shopping-cart';

// Components
// import { CartContext } from '../checkout/CartContext';
import formatPrice from '../utility/formatPrice';
import TimeArrow from '../text/timeArrow';
import { formatTitle } from '../utility/formatTitle';

const CartTable = styled.div`
  max-width: 1100px;
  width: 100%;

  border: 2px solid #d27dff;
  border-bottom: 0;
  border-top: 0;

  background-color: #fff;
`;

const CartHeaders = styled.div`
  display: flex;
  justify-content: between;

  background-color: #d27dff;
  color: #fff;

  text-transform: uppercase;

  & > div {
    &:last-of-type {
      border-right: 0;
    }
  }
`;

const CartText = styled.div`
  padding: ${props => props.padding || '24px 22px'};
  flex: ${props => props.flex || '0 0 25%'};
  max-width: ${props => props.maxWidth || '25%'};

  font-size: 16px;
  text-transform: ${props => props.case || 'inherit'};
  text-align: ${props => props.align || 'left'};
  font-feature-settings: 'tnum';

  @media (max-width: 767px) {
    font-size: 12px;
    padding: 5px;
  }

  // @media (max-width: 575px) {
  //   flex: 0 0 25%;
  //   max-width: 25%;
  // }
`;

const QuantityContainer = styled(CartText)`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-end;

  @media (max-width: 767px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

const CartLine = styled.div`
  font-size: 20px;

  display: flex;
  flex-direction: row;
  justify-content: between;
  align-items: center;

  border-bottom: 2px solid #d27dff;

  & a {
    text-decoration: none;
  }
`;

const CartTotal = styled.div`
  display: flex;
  justify-content: space-between;

  max-width: 1100px;
  width: 100%;

  background-color: #fff;
  margin-top: 30px;

  padding: 17px;
  border: 2px solid #d27dff;

  @media (max-width: 767px) {
    & h2 {
      font-size: 18px;
    }
  }
`;

const CheckoutButton = styled.button`
  max-width: 1100px;
  width: 100%;

  padding: 17px;

  color: #fff;
  background-color: #d27dff;

  font-size: 24px;

  display: flex;
  justify-content: space-between;

  @media (max-width: 767px) {
    font-size: 18px;
  }
`;

const TotalPrice = styled.h2``;

const Quantity = styled.button`
  color: #fff;
  background-color: #333;
  border: 2px solid #333;
  border-radius: 50%;

  position: relative;

  width: 17px;
  height: 17px;

  &:first-of-type {
    margin-right: 10px;

    & span {
      top: -3.5px;
      left: 2.5px;
    }
  }

  &:last-of-type {
    margin-left: 10px;

    & span {
      top: -3.5px;
      left: 2px;
    }
  }

  & span {
    line-height: 16px;
    height: 16px;
    position: absolute;
  }

  &:hover {
    color: #000;
    background-color: #fff;
  }

  @media (max-width: 767px) {
    &:first-of-type {
      margin-right: 5px;
      & span {
        top: -2.5px;
        left: 2.5px;
      }
    }
    &:last-of-type {
      margin-left: 5px;
      & span {
        top: -3px;
        left: 3px;
      }
    }
  }
`;

export const DisplayCheckout = () => {
  const {
    totalPrice,
    redirectToCheckout,
    cartCount,
    cartDetails,
    decrementItem,
    incrementItem,
  } = useShoppingCart();

  const handleSubmit = async () => {
    const formattedCart = Object.entries(cartDetails);

    const response = await fetch(
      `/.netlify/functions/create-checkout-session`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formattedCart),
      }
    )
      .then(res => {
        return res.json();
      })
      .catch(error => console.log(error));

    redirectToCheckout({ sessionId: response.sessionId });
  };

  const cart = Object.entries(cartDetails);

  const content = cart.map((product, index) => {
    if (product[1].productType === 'goods') {
      return (
        <CartLine key={index}>
          <CartText
            align={'left'}
            case={'uppercase'}
            padding={'24px 11px 24px 11px'}
            flex={'0 0 40%'}
            maxWidth={'40%'}
          >
            <Link to={product[1].uid}>{formatTitle(product[1].name)}</Link>

            {product[1].details.product_details_1 && (
              <div>
                <br />■ {product[1].details.product_details_1}
              </div>
            )}
            {product[1].details.product_details_2 && (
              <div>● {product[1].details.product_details_2}</div>
            )}

            {product[1].details.product_details_3 && (
              <div>▲{product[1].details.product_details_3}</div>
            )}

            {product[1].details.product_details_4 && (
              <div>☀ {product[1].details.product_details_4}</div>
            )}
          </CartText>

          <CartText
            align={'center'}
            padding={'24px 11px 24px 11px'}
            flex={'0 0 15%'}
            maxWidth={'15%'}
          >
            {formatPrice(product[1].price / 100, 'gbp')}
          </CartText>

          <QuantityContainer
            align={'center'}
            padding={'24px 11px 24px 11px'}
            flex={'0 0 25%'}
            maxWidth={'25%'}
          >
            <Quantity onClick={() => decrementItem(product[0])}>
              <span>−</span>
            </Quantity>
            {product[1].quantity}

            <Quantity onClick={() => incrementItem(product[0])}>
              <span>+</span>
            </Quantity>
          </QuantityContainer>

          <CartText
            align={'center'}
            padding={'24px 11px 24px 11px'}
            flex={'0 0 20%'}
            maxWidth={'20%'}
          >
            {formatPrice((product[1].quantity * product[1].price) / 100, 'gbp')}
          </CartText>
        </CartLine>
      );
    } else if (product[1].productType === 'event') {
      return null;
      // return (
      //   <CartLine key={index}>
      //     <CartText
      //       align={'left'}
      //       case={'uppercase'}
      //       padding={'24px 11px 24px 11px'}
      //       flex={'0 0 40%'}
      //       maxWidth={'40%'}
      //     >
      //       <Link to={product[1].uid}>{formatTitle(product[1].name)}</Link>

      //       <div>
      //         <br />■ {product[1].details.date}
      //       </div>

      //       <div>
      //         ● {product[1].details.start_time} <TimeArrow>→</TimeArrow>{' '}
      //         {product[1].details.finish_time}
      //       </div>

      //       <div>▲ {product[1].details.location.document.data.title.text}</div>

      //       <div>☀ {product[1].details.tutor.document.data.name.text}</div>
      //     </CartText>

      //     <CartText
      //       align={'center'}
      //       padding={'24px 11px 24px 11px'}
      //       flex={'0 0 15%'}
      //       maxWidth={'15%'}
      //     >
      //       {formatPrice(product[1].price / 100, 'gbp')}
      //     </CartText>

      //     <QuantityContainer
      //       align={'center'}
      //       padding={'24px 11px 24px 11px'}
      //       flex={'0 0 25%'}
      //       maxWidth={'25%'}
      //     >
      //       <Quantity onClick={() => decrementItem(product[0])}>
      //         <span>−</span>
      //       </Quantity>
      //       {product[1].quantity}

      //       <Quantity onClick={() => incrementItem(product[0])}>
      //         <span>+</span>
      //       </Quantity>
      //     </QuantityContainer>

      //     <CartText
      //       align={'center'}
      //       padding={'24px 11px 24px 11px'}
      //       flex={'0 0 20%'}
      //       maxWidth={'20%'}
      //     >
      //       {formatPrice((product[1].quantity * product[1].price) / 100, 'gbp')}
      //     </CartText>
      //   </CartLine>
      // );
    }
  });

  return (
    <>
      <CartTable>
        <CartHeaders>
          <CartText align={'center'} flex={'0 0 40%'} maxWidth={'40%'}>
            Product
          </CartText>

          <CartText align={'center'} flex={'0 0 15%'} maxWidth={'15%'}>
            Price
          </CartText>

          <CartText align={'center'} flex={'0 0 25%'} maxWidth={'25%'}>
            Qty
          </CartText>
          <CartText align={'center'} flex={'0 0 20%'} maxWidth={'20%'}>
            Total
          </CartText>
        </CartHeaders>
        {content}
      </CartTable>

      <CartTotal>
        <h2>TOTAL</h2>
        <TotalPrice>{formatPrice(totalPrice / 100, 'gbp')}</TotalPrice>
      </CartTotal>
      <CheckoutButton onClick={() => handleSubmit()} disabled={cartCount === 0}>
        Checkout <TimeArrow>→</TimeArrow>
      </CheckoutButton>
    </>
  );
};
