import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { Row, Col } from 'styled-bootstrap-grid';

// Components
import Title from '../components/text/title';
import ContentContainer from '../components/global/contentContainer';
import { DisplayCheckout } from '../components/checkout/DisplayCheckout';

const ContainerTheme = {
  backgroundColor: '#dcbeff',
};

const CheckoutStage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  margin-bottom: 54px;

  @media (max-width: 767px) {
    flex-direction: column;
    margin: 0;
  }
`;

const Stage = styled.p`
  font-size: 20px;
  color: ${props => (props.active ? 'black' : '#d27dff') || 'black'};

  margin: 0 30px 0 0;

  &:last-of-type {
    margin: 0;
  }

  @media (max-width: 767px) {
    margin: 0;
    text-align: center;
  }
`;

const CartContainer = styled.div`
  margin: 0 27px;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    margin: 0;
  }
`;

const Checkout = () => (
  <>
    <Helmet title={'Checkout'} />
    <Row css={ContainerTheme} justifyContent="center">
      <Col col={12}>
        <ContentContainer padding={'0 36px'}>
          <Title margin={'80px 0 36px 0'} text={'CHECKOUT'} case="inherit" />
        </ContentContainer>
      </Col>

      <Col col={12}>
        <ContentContainer padding={'0 36px'} mobilePadding={'0 0 40px 0'}>
          <CheckoutStage>
            <Stage active={true}>➊ Shopping Cart</Stage>
            <Stage>➁ Order Confirmed</Stage>
          </CheckoutStage>
        </ContentContainer>
      </Col>

      <Col col={12}>
        <ContentContainer padding={'0 36px 54px'} mobilePadding={'0 0 40px 0'}>
          <CartContainer>
            <DisplayCheckout />
          </CartContainer>
        </ContentContainer>
      </Col>
    </Row>
  </>
);

export default Checkout;
